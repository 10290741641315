import React, { Component } from "react";
import { connect } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";

import OtpInput from "react-otp-input";
import Button from "../../components/Button";
import { verify } from "../../redux/actions";
import Modal from "../../components/Modal";

import axios from 'axios';

class Otp extends Component {
  _mounted = true;

  constructor(props) {
    super(props);

    this.state = {
      url: process.env.REACT_APP_BASE_URL,
      email: props.match.params.email || "",
      otp: "",
      disabled: true,
      isDisabledResend: true,
      loading: false,
      timeLeft: null,
      alert: {
        show: false,
        message: "",
        isError: false,
      },
      modal: {
        show: false,
        body: "",
        onSubmit: () => {},
      },
    };
  }

  componentDidMount() {
    const otp = localStorage.getItem('expiredOtp');
    const timeLeft = otp - Date.now();

    if(!otp || timeLeft <= 0) {
      this.sendOtp();
    } else {
      this.startCountdown();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if expiredAt has changed, and update timeLeft if necessary
    if (prevState.timeLeft !== this.state.timeLeft) {
      if (this.state.timeLeft === 0) {
        this.setState({ isDisabledResend: false });
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this.interval);
  }

  onVerify = async () => {
    this.setState({ loading: true });
    try {
      await this.props.onVerify(this.state.email, this.state.otp);
      if (this._mounted) this.setState({ loading: false });
      this.props.history.replace("/beranda");
    } catch (error) {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            body: error.message,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  handleOtpChange = (otp) => {
    this.setState({ otp });

    if (otp.length === 6) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  };

  sendOtp = async () => {
    this.setState({ loading: true });

    const { email, url } = this.state;
    const dataBody = { email };

    const response = await axios.post(
        `${url}/conductor/otp/send`, dataBody,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    );

    if (response?.status === 200) {
      this.startCountdown();

      localStorage.setItem('expiredOtp', response.data.data.expired_at);

      this.setState({
        loading: false
      });
    } else {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            body: response.data.message,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  startCountdown = () => {
    const expirationDate = Date.now() + 2 * 60 * 1000; // Set expiration to 2 minutes from now

    this.interval = setInterval(() => {
      const timeLeft = expirationDate - Date.now(); // Calculate remaining time

      if (timeLeft <= 0) {
        clearInterval(this.interval); // Stop the countdown when time runs out
        this.setState({ timeLeft: 0 });
      } else {
        this.setState({ timeLeft });
      }
    }, 1000); // Update the countdown every second
  };

  handleResend = async () => {
    this.setState({ loading: true });

    const { email, url } = this.state;
    const dataBody = { email };

    const response = await axios.post(
        `${url}/conductor/otp/resend`, dataBody,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    );

    if (response?.status === 200) {
      localStorage.setItem('expiredOtp', response.data.data.expired_at);

      if (this._mounted) {
        this.startCountdown();

        this.setState({
          otp: "",
          loading: false,
          isDisabledResend: true,
          modal: {
            show: true,
            body: response.data.message,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    } else {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            body: response.data.message,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  formatTime = (ms) => {
    if (ms <= 0) return "00:00"; // If time has expired, return 00:00

    const minutes = Math.floor(ms / (1000 * 60)); // Calculate minutes
    const seconds = Math.floor((ms % (1000 * 60)) / 1000); // Calculate seconds

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`; // Format time as MM:SS
  };

  render() {
    return (
      <div className="w-screen h-screen grid grid-cols-1 md:grid-cols-3 bg-background">
        <DotLoaderOverlay
          loading={this.state.loading}
          overlayColor="rgba(0,0,0,0.1)"
          color={"#CF1701"}
        />
        <Modal
          show={this.state.modal.show}
          body={this.state.modal.body}
          textSubmit="OK"
          onSubmit={this.state.modal.onSubmit}
        />
        <div className="h-full col-span-2 hidden md:block">
          <div className="h-full w-full flex">
            <div className="m-auto">
              <img
                alt="Caready Logo"
                className="w-80 h-100"
                src={require("../../assets/images/caready_vertical_logo.png")}
              />
            </div>
          </div>
        </div>
        <div className="h-full w-full bg-white p-6 min-h-screen flex flex-col justify-between">
          <div>
            <img
              alt="Caready Logo"
              className="w-10 h-10"
              src={require("../../assets/images/logo-circle.png")}
            />
          </div>
          <div className="">
            <div className="m-auto font-sourceSansPro">
              <h3 className="font-semibold text-xl mb-8">Verifikasi OTP</h3>
              <div className="me-2 text-secondary text-sm mb-4">Masukkan kode OTP Verifikasi yang telah dikirim ke alamat email <b>{this.state.email}</b></div>
              <div className="mb-8">
                <OtpInput
                  value={this.state.otp}
                  onChange={this.handleOtpChange}
                  numInputs={6}
                  renderSeparator={<span style={{ width: "100px" }}></span>}
                  renderInput={(props) => <input {...props} />}
                  inputType="number"
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    border: "1px solid gray",
                  }}
                />
              </div>

              <Button bgColor={this.state.disabled && 'bg-gray-500'} disabled={this.state.disabled} className="w-full" title="Verifikasi OTP" onClick={this.onVerify} />

              {!this.state.isDisabledResend ? (
                <div
                  className="text-sm flex mt-8"
                  style={{ justifyContent: "center" }}
                >
                  <div className="me-2 text-secondary">Belum menerima OTP Code?</div>
                  <div className="text-primary cursor-pointer" onClick={this.handleResend}>
                    Resend
                  </div>
                </div>
              ) : (
                <div
                  className="flex text-sm mt-8"
                  style={{ justifyContent: "center" }}
                >
                  <div className="me-2 text-secondary">Kirim ulang kode setelah</div>
                  <div
                    className="text-primary"
                  >
                    {this.formatTime(this.state.timeLeft)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex text-xs">
            <div className="mr-2">CAREADY © 2020 all right reserved</div>
            {/* <div className="text-primary hover:cursor-pointer hover:underline">Bantuan</div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onVerify: (email, otp) => dispatch(verify(email, otp)),
  };
};

export default connect(null, mapDispatchToProps)(Otp);
