import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from '../layout/Layout';
import Login from '../pages/auth/Login';
import Otp from '../pages/auth/Otp';
import Beranda from '../pages/main/Beranda';
import Riwayat from '../pages/main/Riwayat';
import DetailRiwayat from '../pages/main/DetailRiwayat';
import LiveBidding from '../pages/main/LiveBidding';
import Logout from '../pages/auth/Logout';
import TestFe from '../pages/page-sentry-tes/TestFE';

const Router = (props) => {
  const token = useSelector((state) => state.auth.token);
  const isFirstLoggedIn = useSelector((state) => state.auth.isFirstLoggedIn);
  const profile = useSelector((state) => state.auth.profile);

  if (!token) {
    return (
      <Switch>
        <Route path="/page-sentry-tes" exact component={TestFe} />
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/otp/:email" exact component={Otp} />
        {!isFirstLoggedIn && window.location.pathname === '/login' ? <Redirect to="/" /> : null}
      </Switch>
    );
  }

  return (
    <Layout profile={profile}>
      <Switch>
        <Route path="/" exact component={Beranda} />
        <Route path="/beranda" exact component={Beranda} />
        <Route path="/riwayat" exact component={Riwayat} />
        <Route path="/detail-riwayat/:id" exact component={DetailRiwayat} />
        <Route path="/live-bidding/:id" exact component={LiveBidding} />
        <Route path="/logout" exact component={Logout} />
        {isFirstLoggedIn ? <Redirect to="/" /> : null}
      </Switch>
    </Layout>
  );
};

export default Router;
