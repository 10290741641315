import React from "react";

const Button = (props) => {
  const { title, style, className, onClick, disabled, color, bgColor } = props;

  return (
    <button
      className={` rounded-md px-6 py-1 text-bold  ${className} ${
        disabled && "cursor-default"
      }  ${color || "text-white"} ${bgColor || "bg-primary"}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default Button;
