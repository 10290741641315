import React, { Component } from "react";
import { connect } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { ReactComponent as EyeSVG } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeOffSVG } from "../../assets/icons/eye-off.svg";

import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { login } from "../../redux/actions";
import Modal from "../../components/Modal";

class Login extends Component {
  _mounted = true;

  state = {
    email: "",
    password: "",
    hidePassword: true,
    loading: false,
    alert: {
      show: false,
      message: "",
      isError: false,
    },
    modal: {
      show: false,
      body: "",
      onSubmit: () => {},
    },
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  onLogin = async () => {
    this.setState({ loading: true });
    try {
      await this.props.onLogin(this.state.email, this.state.password);
      if (this._mounted) this.setState({ loading: false });
      this.props.history.replace("/beranda");
    } catch (error) {
      if (this._mounted) {
          const otpErrorMessage = [
            'OTP tidak ditemukan',
            'Silahkan Cek Email Anda',
            'OTP tidak valid',
            'Masa berlaku OTP Anda telah habis, silakan buat OTP baru',
            'OTP sudah tidak berlaku',
          ];

        this.setState({
          loading: false,
          modal: {
            show: true,
            body: error.message,
            onSubmit: () => {
              if(otpErrorMessage.includes(error.message)) {
                this.props.history.push(`/otp/${this.state.email}`)
              } else {
                this.setState({
                  modal: {
                    ...this.state.modal,
                    show: false,
                  },
                });
              }
            },
          },
        });
        // this.setState({loading: false});
      }
    }
  };

  render() {
    return (
      <div className="w-screen h-screen grid grid-cols-1 md:grid-cols-3 bg-background">
        <DotLoaderOverlay
          loading={this.state.loading}
          overlayColor="rgba(0,0,0,0.1)"
          color={"#CF1701"}
        />
        <Modal
          show={this.state.modal.show}
          body={this.state.modal.body}
          textSubmit="OK"
          onSubmit={this.state.modal.onSubmit}
        />
        <div className="h-full col-span-2 hidden md:block">
          <div className="h-full w-full flex">
            <div className="m-auto">
              <img
                alt="Caready Logo"
                className="w-80 h-100"
                src={require("../../assets/images/caready_vertical_logo.png")}
              />
            </div>
          </div>
        </div>
        <div className="h-full w-full bg-white p-6 min-h-screen flex flex-col">
          <div>
            <img
              alt="Caready Logo"
              className="w-10 h-10"
              src={require("../../assets/images/logo-circle.png")}
            />
          </div>
          <div className="h-full w-full flex">
            <div className="m-auto font-sourceSansPro">
              <h3 className="font-semibold text-xl mb-8">Masuk</h3>
              <div className="mb-6 w-80 sm:w-80 md:w-60 lg:w-80">
                <TextInput
                  containerclassname="focus-within:border-primary"
                  label={
                    <span>
                      Email <span className="text-red-500">*</span>
                    </span>
                  }
                  autoComplete='off'
                  placeholder="Email"
                  type="email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="mb-8">
                <TextInput
                  containerclassname="focus-within:border-primary"
                  label={
                    <span>
                      Kata Sandi <span className="text-red-500">*</span>
                    </span>
                  }
                  righticon={
                    this.state.hidePassword ? (
                      <EyeSVG
                        className="hover:cursor-pointer w-6 h-6"
                        fill={"#9AA2B1"}
                        onClick={() =>
                          this.setState({
                            hidePassword: !this.state.hidePassword,
                          })
                        }
                      />
                    ) : (
                      <EyeOffSVG
                        className="hover:cursor-pointer w-6 h-6"
                        fill={"#9AA2B1"}
                        onClick={() =>
                          this.setState({
                            hidePassword: !this.state.hidePassword,
                          })
                        }
                      />
                    )
                  }
                  placeholder="Kata Sandi"
                  type={this.state.hidePassword ? "password" : "text"}
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <Button className="w-full" title="Masuk" onClick={this.onLogin} />
            </div>
          </div>
          <div className="flex text-xs">
            <div className="mr-2">CAREADY © 2020 all right reserved</div>
            {/* <div className="text-primary hover:cursor-pointer hover:underline">Bantuan</div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password) => dispatch(login(email, password)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
